export var Common = function () {


return {
    gCallback: null,
    gPreviousChecksum: null,
    gReportSettingsFunction: null,
    gActiveAdminDomainTabName: 'activeAdminDomainTab',

    // Format a Date to use short month names with a date.
    // Example: 'Nov 11'
    shortMonthNames: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],

    validateCommaSeperatedEmail: function(emailList) {
      var emails = emailList.split(',')

      var valid = true;
      for (var i = 0; i < emails.length; i++) {
          if( !Common.validateEmail(emails[i]) ){
              valid = false;
          }
      }

      return valid;
    },
    loading_overlay: function(action, parent_selector){
      const parent_container = document.querySelector(parent_selector)
      if (parent_container){
        if (action === 'hide'){
          const loading_frame = parent_container.querySelector('.loading-frame')
          if (loading_frame) {
            loading_frame.remove()
          }
          return
        }

        const overlay_div = document.createElement('div')
        overlay_div.classList.add('loading-frame')
        overlay_div.style.width = parent_container.offsetWidth
        overlay_div.style.height = parent_container.offsetHeight

        const loading_loader = document.createElement('div')
        loading_loader.classList.add('loading-loader')

        const dot1 = document.createElement('div')
        dot1.classList.add('dot1')
        const dot2 = document.createElement('div')
        dot2.classList.add('dot2')
        loading_loader.append(dot1)
        loading_loader.append(dot2)
        overlay_div.append(loading_loader)
        parent_container.append(overlay_div)
      }    
    },

    validateEmail: function(email) {
      var regex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return !( email === "" || !regex.test(email.replace(/\s/g, "")))
    },

    save_active_admin_domain_tab: function()
    {
      var v = $('#tablist a.active').attr('href');
      localStorage.setItem(Common.gActiveAdminDomainTabName, v);
    },

    get_active_admin_domain_tab: function()
    {
      return localStorage.getItem(Common.gActiveAdminDomainTabName);
    },

    /**
     * Using this to generate hash value to compare the current domain to
     * a list of known domain hash values.
     * This is needed as a hack to determine the hosting company when on mobile devices
     * and they do not provide data for us to know who the hosting comany is.
     *
     * This code is also used within the AI.js script.
     *
     * @see http://stackoverflow.com/q/7616461/940217
     * @return {number}
     */
    hashCode: function(str){
      if (Array.prototype.reduce){
        return str.split("").reduce(function(a,b){a=((a<<5)-a)+b.charCodeAt(0);return a&a},0);
      }
      var hash = 0;
      if (str.length === 0) return hash;
      for (var i = 0; i < str.length; i++) {
        var character  = str.charCodeAt(i);
        hash  = ((hash<<5)-hash)+character;
        hash = hash & hash; // Convert to 32bit integer
      }
      return hash;
    },

    // Used to perform indexOf on an array of objects
    objectArrayIndexOf: function(array, attr, value){
      for(var i = 0; i < array.length; i += 1) {
        if(array[i][attr] === value) {
            return i;
        }
      }
      return -1;
    },

    /**
    * This is copied from the AI code.
    * The concept is to use this function when you need to wait for
    * an asynchronous event to occur before proceeding. 
    *
    * Wait until the test condition is true or a timeout occurs. Useful for waiting
    * on a server response or for a ui change (fadeIn, etc.) to occur.
    *
    * @param testFx javascript condition that evaluates to a boolean,
    * it can be passed in as a string (e.g.: "1 == 1" or "$('#bar').is(':visible')" or
    * as a callback function.
    * @param onReady what to do when testFx condition is fulfilled,
    * it can be passed in as a string (e.g.: "1 == 1" or "$('#bar').is(':visible')" or
    * as a callback function.
    * @param timeOutMillis the max amount of time to wait. If not specified, 3 sec is used.
    */
    waitFor: function(testFx, onReady, onTimeout, timeOutMillis) {
      var maxtimeOutMillis = timeOutMillis ? timeOutMillis : 3000, //< Default Max Timout is 3s
      start = new Date().getTime(),
      condition = false,
      interval = setInterval(function() {
        if ( (new Date().getTime() - start < maxtimeOutMillis) && !condition ) {
            // If not time-out yet and condition not yet fulfilled
            condition = (typeof(testFx) === "function" ? testFx() : "testFx parameter should be a function");
        } else {
          if(!condition) {
              // If condition still not fulfilled (timeout but condition is 'false')
              console.log("'waitFor()' timeout");
              clearInterval(interval); // Stop this interval
              // Do what it's supposed to do once the condition is fulfilled
              typeof(onTimeout) === "function" ? onTimeout() : "onTimeout parameter should be a function";
          } else {
              // Condition fulfilled (timeout and/or condition is 'true')
              console.log("'waitFor()' finished in " + (new Date().getTime() - start) + "ms.");
              // Do what it's supposed to do once the condition is fulfilled
              typeof(onReady) === "function" ? onReady() : "onReady parameter should be a function";
              clearInterval(interval); // Stop this interval
          }
        }
      }, 250); //< repeat check every 250ms
    },
    
    notify: function(msg, type, element) {
      element = element || 'body'
      $.notify({
        // options
        message: msg
      },{
        // settings
        element: element,
        type: type,
        delay:5000
      });
    },

    //public function to get a paremeter by name from URL
    getURLParameter: function(paramName) {
      var searchString = window.location.search.substring(1),
        i, val, params = searchString.split("&");
      for (i = 0; i < params.length; i++) {
          val = params[i].split("=");
          if (val[0] == paramName) {
              return unescape(val[1]);
          }
      }
      return null;
    },

    imageError: function(image)
    {
      var car_placeholder = '/assets/cars/no_image_available.jpg';
      image.onerror = "";
      image.src = car_placeholder;
      return true;
    },
    initCarImageDefault: function()
    {
      var car_placeholder = '/assets/cars/no_image_available.jpg';
      $(".car_image").error(function(){
        $(this).attr('src', car_placeholder);
      });
    },

    // Save the clicked sidebar item to localstorage
    persistSidebar: function(last_clicked_nav_id) {
      if(last_clicked_nav_id != undefined){
        localStorage.setItem("last_clicked_nav_id", last_clicked_nav_id);
      }
    },

    in_crm_mode: function()
    {
      var v = (typeof(IN_CRM_MODE)!=="undefined") && IN_CRM_MODE;
      return v;
    },

    // Used to show the appropriate sidebar items as active from the
    // previously saved item id.
    activateSidebar: function() {
      if (Common.in_pdf_mode() || Common.in_crm_mode()) return;

      if (localStorage["last_clicked_nav_id"]) {
        var current_action = $('#action-name').val();
        var last_clicked_nav_item = localStorage["last_clicked_nav_id"];
        if (current_action == last_clicked_nav_item || $('body').hasClass( last_clicked_nav_item )) {
          var activeAttribute = document.querySelector(`[data-nav_id=${localStorage.getItem("last_clicked_nav_id")}]`)
          activeAttribute.parentElement.className += " active"
          //this has a bad var name, but it essentialy creates a jQuery id of the activeAttribute, then calls it's ancestors
          var jqId = $(`a[data-nav_id="${localStorage["last_clicked_nav_id"]}"]`)
          $(jqId).parents().eq(2).addClass(" active")
          $(jqId).parents().eq(4).addClass(" active")
          $(jqId).parents().eq(6).addClass(" active")
        }
      }

      // calculate the window height and set the sidebar height for scrolling
      // Common.setSidebarHeight();
      // $(window).resize(function(){Common.setSidebarHeight()});
    },

    init: function()
    {
      console.log('init');
      // Disable chart animations when rendering pdf
      if (Common.in_pdf_mode())
      {
        Highcharts.setOptions({
          plotOptions: {
            series: {
              animation: false
            }
          }
        });
      }

      Highcharts.setOptions({ lang: { decimalPoint: '.', thousandsSep: ',' } });

      Common.initNavigationLinks();
      Common.activateSidebar();
      Common.initWidgets();
      Common.updateBranding();
    },
    initWidgets: function()
    {
      Common.initWidgetRefresh();
    },

    // http://stackoverflow.com/questions/359788/how-to-execute-a-javascript-function-when-i-have-its-name-as-a-string
    executeFunctionByName: function(functionName, context, args)
    {
      var args = [].slice.call(arguments).splice(2);
      var namespaces = functionName.split(".");
      var func = namespaces.pop();
      for(var i = 0; i < namespaces.length; i++) {
        context = context[namespaces[i]];
      }
      return context[func].apply(this, args);
    },

    initWidgetRefresh: function()
    {
      $(".panel-refresh").on("click",function(){
          var panel = $(this).parents(".panel");
          var callback = $(this).attr('data-callback');
          if (callback !== 'undefined')
          {
            Common.executeFunctionByName(callback, window, Common.getSelectedDomainId());
          }
          else
          {
            panel_refresh(panel);
          }
          return false;
      });
    },
    // Pass in a table_selector to find and format
    // all data with the appropriate classes.
    formatTableValues: function(table_selector)
    {
      $(table_selector + ' .currency').each(function(){
        var v = $(this).text();
        v = numeral( v ).format('$0,0.00');
        $(this).text(v);
      });

      $(table_selector + ' .currency_no_cents').each(function(){
        var v = $(this).text();
        v = numeral( v ).format('$0,0');
        $(this).text(v);
      });

      $(table_selector + ' .numeric').each(function(){
        var v = $(this).text();
        v = numeral( v ).format('0,0');
        $(this).text(v);
      });

      $(table_selector + ' .decimal').each(function(){
        var v = $(this).text();
        v = numeral( v ).format('0,0.00');
        $(this).text(v);
      });

      $(table_selector +  ' .percentage').each(function(){
        var v = $(this).text();
        v = numeral( v ).format('0%');
        $(this).text(v);
      });

      $(table_selector +  ' .duration').each(function(){
        var v = $(this).text();
        v = numeral( v ).format('00:00:00');
        $(this).text(v);
      });

      $(table_selector +  ' .audio_player_duration').each(function(){
        var v = $(this).text();
        v = moment.utc(moment.duration( parseInt(v), "seconds").asMilliseconds()).format("H:m:ss")
        v = v.replace("0:","");
        $(this).text(v);
      });
    },

    formatPhoneNumber: function(str){
      //Filter only numbers from the input
      var cleaned = ('' + str).replace(/\D/g, '');

      //Check if the input is of correct length
      var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

      if (match) {
        return "(" + match[1] + ') ' + match[2] + '-' + match[3]
      }

      return null
    },

    getSelectedAutoGroupIdFromCookie: function()
    {
      let result = 0;
      var id = Cookies.get('aid_selected_auto_group_id');
      if (id !== undefined && id !== '')
      {
        result = id;
      }
      return result;
    },

    getSelectedSiteIdFromCookie: function()
    {
      let result = 0;
      var site_id = Cookies.get('aid_selected_site_id');
      if (site_id !== 'undefined' && site_id !== '')
      {
        result = site_id;
      }
      return result;
    },

    initNavigationLinks: function()
    {
      // Code to set the correct navigation link to the
      //  appropriate view.
      var current_action = $('#action-name').val();
      $('.page-sidebar .navigation-links').removeClass('active');
      var links = $('.page-sidebar .navigation-links a[href*=' + current_action + ']');
      $(links[0]).parent().addClass('active');

      Common.initSidebarReportLinks();
    },
    // When a report link is clicked on, we need to build the
    // url dynamically as the user may choose which site
    // is in use.
    initSidebarReportLinks: function()
    {
      $('.page-sidebar .user-action a').click(function(e){
        e.preventDefault();
        e.stopPropagation();
        var href = $(this).attr('href');
        var site_id = Common.getSelectedDomainId();
        if ($(this).hasClass('direct'))
        {
          window.location.href = href;
        }
        else if ($(this).hasClass('sites'))
        {
          window.location.href = href;
        }
        else if ($(this).attr('data-medium-type'))
        {
          var medium_type = $(this).attr('data-medium-type');
          window.location.href = '/acquisition/detail/' + medium_type;
        }
        else if ($(this).attr('data-report-type'))
        {
          var report_type = $(this).attr('data-report-type');
          var url = '/sites/' + site_id + '/inventory/' + report_type
          window.location.href = url;
        }
        else if ($(this).attr('data-con-type'))
        {
          var con_type = $(this).attr('data-con-type');
          var url = '/sites/' + site_id + '/page_performance/' + con_type
          window.location.href = url;
        }
        else if ($(this).attr('data-cd-type') && $(this).attr('data-alert-cd-type') && $(this).attr('data-appt-cd-type') && $(this).attr('data-cd-prospects') && $(this).attr('data-connected-type'))
        {
          var cd_type = $(this).attr('data-cd-type');
          var cd_prospects = $(this).attr('data-cd-prospects');
          var appt_cd_type = $(this).attr('data-appt-cd-type');
          var alert_cd_type = $(this).attr('data-alert-cd-type');
          var connected_type = $(this).attr('data-connected-type');
          var url = '/filtered_call_details/' + cd_type + '/' + cd_prospects + '/' + appt_cd_type + '/' + alert_cd_type + '/' + connected_type
          window.location.href = url;
        }
        else if ($(this).attr('data-chat-type'))
        {
          var chat_type = $(this).attr('data-chat-type');
          var url = '/chat_details/' + chat_type
          window.location.href = url;
        }
        else if ($(this).attr('data-lead-type'))
        {
          var lead_type = $(this).attr('data-lead-type');
          var url = '/lead_details/' + lead_type
          window.location.href = url;
        }
        else if ($(this).attr('data-text-type'))
        {
          var text_type = $(this).attr('data-text-type');
          var url = '/text_details/' + text_type
          window.location.href = url;
        }
        else
        {
          var admin_type = $(this).attr('data-domain-admin');
          var url = '/admin/domains'
          window.location.href = url;
        }
      });
    },
 
    getSelectedDomainIdFromHiddenElement: function()
    {
      return $('#domain_id').val();
    },

    // Gets selected domain id from the DOM element or
    // from the cookie.
    getSelectedDomainId: function()
    {
      var domain_id = Common.getSelectedSiteIdFromDomainMenu();
      if (_.isUndefined(domain_id) || domain_id === '' || domain_id == 'null' || domain_id == null)
      {
        domain_id = Common.getSelectedSiteIdFromCookie();
        $('select#site-navigation').val(domain_id);
      }

      // The hidden element overrides other values
      var hidden_domain_id = Common.getSelectedDomainIdFromHiddenElement();
      if (! _.isUndefined(hidden_domain_id) && hidden_domain_id !== '')
      {
        domain_id = hidden_domain_id;
      }
      return domain_id;
    },
    getSelectedSiteIdFromDomainMenu: function()
    {
      return $('select#site-navigation').val();
    },
    getWidgetData: function()
    {
      var site_id = Common.getSelectedDomainId();
      abort_ajax_requests();

      // Refresh UI with new data requests.
      // Cookies.set('aid_selected_site_id', site_id, {secure: true, sameSite: "None"}); //should be able to delete this
      this.setCookie('aid_selected_site_id', site_id); //should be able to delete this
      Common.gCallback(site_id);
    },

    updateBranding: function(update_branding = null)
    {
      console.log("init");
      var settings = Common.get_auto_group_settings();
      console.log(settings);
      if ((settings && (settings.branding !== undefined && settings.branding !== null))  || update_branding != null) {
        var checksum = Common.get_branding_checksum();
        if (checksum == Common.gPreviousChecksum && (typeof checksum !== 'undefined'))
        {
          // console.log('same branding checksum')
          return;
        }

        Common.gPreviousChecksum = checksum;
        var branding = update_branding != null ? update_branding : settings.branding;
        Common.clear_custom_branding();
        Common.update_logo(branding.logo_url, branding.small_logo);
        Common.update_header_color(branding.header_color);
        Common.update_messaging_header_color(branding.header_color);
        Common.update_sidebar_color(branding.sidebar_color);
        Common.update_highlight_color(branding.highlight_color);
        Common.update_active_color(branding.active_color);
        Common.update_header_text_color(branding.header_text_color);
        Common.update_messaging_header_text_color(branding.header_text_color);
        Common.update_sidebar_text_color(branding.sidebar_text_color);

        Common.update_panel_header_color(branding.panel_header_color);
        Common.update_panel_text_color(branding.panel_text_color);
        Common.update_table_header_color(branding.table_header_color);
        Common.update_table_text_color(branding.table_text_color);

      }
    },

    getBranding: function() {
      var settings = Common.get_auto_group_settings();
      if ((settings && (settings.branding !== undefined && settings.branding !== null))  || update_branding != null) {
        return settings.branding;
      } else {
        return null;
      }
    },

    clear_custom_branding: function()
    {
      var style_container = document.getElementById('custom_branding');

      if(style_container) {
        style_container.textContent = ''
      }
    },
    
    add_custom_branding: function(style_text)
    {
      var style_container = document.getElementById('custom_branding');
      var text_node = document.createTextNode(style_text);
      if(style_container) {
        style_container.appendChild(text_node);
      }
    },

    update_header_text_color: function(v)
    {
      $('.x-navigation.x-navigation-horizontal li > a, .x-navigation.x-navigation-horizontal li > span#domain_label, .x-navigation.x-navigation-horizontal li > a .fa, .x-navigation.x-navigation-horizontal li > .glyphicon').css('color', v);
      $('.x-navigation.x-navigation-horizontal li > a, .x-navigation.x-navigation-horizontal li > span#auto_group_label, .x-navigation.x-navigation-horizontal li > a .fa, .x-navigation.x-navigation-horizontal li > .glyphicon').css('color', v);
      $('.navbar li > a, .navbar li > span, .navbar li > a .fa, .navbar li > .glyphicon, .navbar .dropdown-toggle').css('color', v);
    },
    update_sidebar_text_color: function(v)
    {
      $('.x-navigation:not(.x-navigation-horizontal) li > a').css('color', v);
      $('.x-navigation:not(.x-navigation-horizontal) li > a .fa, .x-navigation:not(.x-navigation-horizontal) li li > a .glyphicon').css('color', v);
      $('.message_center_sidebar_icon').css('background-color', v);

      // logout menu
      $('.dropdown-menu li > a').css('color', v);
      Common.add_custom_branding('.dt-button a {color : ' + v + ' !important;}')
    },
    update_panel_header_color: function(v) {
      Common.add_custom_branding(`.panel-default .panel-heading {background-color: ${v}}`);
    },
    update_panel_text_color: function(v) {
      
      Common.add_custom_branding(`.panel .panel-title, .panel-default > .panel-heading, ul.panel-controls > li > a {color: ${v}}`);
      Common.add_custom_branding(`ul.panel-controls > li > a {border: 1px solid ${v}}`);
    },
    update_table_header_color: function(v) {
      Common.add_custom_branding(`.drilltable > thead > tr > th {background-color: ${v}}`);
    },
    update_table_text_color: function(v) {
      Common.add_custom_branding(`.drilltable > thead > tr > th {color: ${v}}`);
    },
    update_logo: function(logo_url, small_logo)
    {
      // Large header
      Common.add_custom_branding('.x-navigation:not(.x-navigation-minimized) > li.xn-logo > a:first-child {background: ' + 'url("' + logo_url + '") top center no-repeat  !important; background-size: contain !important;}')
      Common.add_custom_branding('.navbar-nav > li.xn-logo > a:first-child {background: ' + 'url("' + logo_url + '") center left no-repeat  !important;}')
      
      // if ($('img#logo_placeholder').length > 0) {
      //   $('img#logo_placeholder').attr('src', logo_url);
      // }

      // Small logo
      // If we are using the default autoid logo, use the default autoid small logo
      // otherwise, use no logo since we do not allow the users to upload a mini logo yet.
      if (logo_url.indexOf('/assets/') == 0) {
        Common.add_custom_branding('.x-navigation.x-navigation-minimized > li.xn-logo > a:first-child {background-image: url("/assets/logo-newco-small@2x.png")  !important;}')
        Common.add_custom_branding('.navbar-nav > li.xn-logo > a:first-child {background-image: url("/assets/logo-newco-small@2x.png")  !important;}')
      } else {
        Common.add_custom_branding('.x-navigation.x-navigation-minimized > li.xn-logo > a:first-child {background-image: none  !important;}')
      }
    },

    update_header_color: function(v)
    {
      Common.add_custom_branding('.page-content .x-navigation {background-color : ' + v + ' !important;}')
      Common.add_custom_branding('.page-content .navbar {background-color : ' + v + ' !important;}')
      Common.add_custom_branding('.page-content .navbar .nav {background-color : ' + v + ' !important;}')
      Common.add_custom_branding('.page-content .navbar ul.nav > li {background-color : ' + v + ' !important;}')
      Common.add_custom_branding('ul.nav .open > a, ul.nav .open > a:hover, ul.nav .open > a:focus {background-color : ' + v + ' !important;}')
      $('.dropdown-menu li > a').css('background-color', v);
    },

    update_messaging_header_color: function(v)
    {
      Common.add_custom_branding('.page-messaging-header .x-navigation {background-color : ' + v + ' !important;}')
      Common.add_custom_branding('.page-messaging-header .navbar {background-color : ' + v + ' !important;}')
      Common.add_custom_branding('.page-messaging-header .navbar .nav {background-color : ' + v + ' !important;}')
      Common.add_custom_branding('.page-messaging-header .navbar ul.nav > li {background-color : ' + v + ' !important;}')
      Common.add_custom_branding('ul.nav .open > a, ul.nav .open > a:hover, ul.nav .open > a:focus {background-color : ' + v + ' !important;}')
      $('.dropdown-menu li > a').css('background-color', v);
    },

    update_messaging_header_text_color: function(v)
    {
      Common.add_custom_branding('message-headers a {color : ' + v + ' !important;}')
      Common.add_custom_branding('message-headers .filter-selected {border : ' + v + ' !important;}')
    },

    update_sidebar_color: function(v)
    {
      Common.add_custom_branding('.page-sidebar .x-navigation {background-color : ' + v + ' !important;}')
      Common.add_custom_branding('.page-sidebar ul li a {background-color : ' + v + ' !important;}')
      Common.add_custom_branding('#page-sidebar-container {background-color : ' + v + ' !important;}')
      Common.add_custom_branding('.x-navigation .dropdown-menu {background-color : ' + v + ' !important;}')
      Common.add_custom_branding('.dropdown-menu > .active > a, .dropdown-menu > .active > a:focus {background-color : ' + v + ' !important;}')
    },

    update_highlight_color: function(v)
    {
      Common.add_custom_branding('.dropdown-menu a:hover {background: ' + v + ' !important;}')
      Common.add_custom_branding('.x-navigation li > a:hover {background: ' + v + ' !important;}')
      Common.add_custom_branding('.x-navigation .x-navigation-control:hover {background : ' + v + ' !important;}')
      Common.add_custom_branding('.x-navigation > li.xn-logo > a:first-child:hover {background-color: ' + v + ' !important;}')
      // Common.add_custom_branding('.x-navigation.x-navigation-horizontal > li > a:hover {background: ' + v + ' !important;}')
      Common.add_custom_branding('.dt-button-collection > li > a:hover {background-color : ' + v + ' !important;}')
    },

    update_active_color: function(v)
    {
      Common.add_custom_branding('.x-navigation li.active > a {background : ' + v + ' !important;}')
      Common.add_custom_branding('.x-navigation li > ul li.active > a {background : ' + v + ' !important;}')
      Common.add_custom_branding('.dt-button-collection > li:not(active) {background-color : ' + v + ' !important;}')
      Common.add_custom_branding('.dt-button-collection > li:not(active) > a:focus {background-color : ' + v + ' !important;}')
    },

    siteNavigationMenu: function(callback)
    {
      Common.gCallback = callback;
      $('select#site-navigation').change(function(){
        Common.refreshDomainSettings();
      });

      Common.autoGroupMenu();
    },

    // When the domain selection changes, we need
    // to rebuild the domain settings cookie.
    // The goal is to only rebuild the domain settings cookie
    // only when the domain actually changes.
    refreshDomainSettings: function()
    {
      var id = Common.getSelectedSiteIdFromDomainMenu();
      // Cookies.set('aid_selected_site_id', id, {secure: true, sameSite: "None"});
      this.setCookie('aid_selected_site_id', id);
      $.get('/domains/' + id + '/settings', function(data){
        Common.getWidgetData();
      },'json');
    },

    autoGroupMenu: function()
    {
      // in crm mode, the auto group menu is not rendered
      // so get the group value from the cookie.
      if (Common.in_crm_mode()) {
        var id = Common.getSelectedAutoGroupIdFromCookie();
        $.get('/auto_groups/domains/' + id, function(data){
         Common.getWidgetData();
        },'json');
      }
      else {
        $('select#auto-group-navigation').change(function(){
          Common.autoGroupSelectionChanged();
        });

        // Select any previously selected site.
        var id = Common.getSelectedAutoGroupIdFromCookie();
        if (id !== 0)
        {
          $('select#auto-group-navigation option[value="' + id + '"]').prop('selected', true)
          $('select#auto-group-navigation').trigger('change');
        }
      }
    },

    getSelectedAutoGroupIdFromMenu: function()
    {
      return $('select#auto-group-navigation').val();
    },

    setCookie(cookie_name, value, path) {
      var options = {};
      if (window.location.protocol === 'https:') {
        options.secure = true;
        options.sameSite = "None";
      }

      if (path) {
        options.path = path;
      }
      
      Cookies.set(cookie_name, value, options);
    },

    autoGroupSelectionChanged: function()
    {
      let id = Common.getSelectedAutoGroupIdFromMenu();
      console.log('setting aid_selected_auto_group_id: ' + id);
      this.setCookie('aid_selected_auto_group_id', id);

      var group_name = $('select#auto-group-navigation option:selected').text();
      $(document).prop('title', group_name);

      console.log('auto group selected: ' + id);

      // Show only domains specific to group
      // Update branding
      $.get('/auto_groups/domains/' + id, function(data){
        Common.rebuild_domains_menu(data);
        Common.updateBranding();
        Common.getWidgetData();
        Common.update_sidebar_for_user_roles(data.roles);
      },'json');
    },

    /*
      When the group changes, we may need to hide/show links
      in the sidebar based on the user's roles for the current group.
    */
    update_sidebar_for_user_roles: function(roles)
    {
      // If the user is an admin or account admin for any group, enable all the admin related links
      if (roles['is_admin_or_account_admin_for_any_group']) {
        $('*[data_allowed_roles]').each(function(){
          $(this).show();
        });
      }
      else {
        $('*[data_allowed_roles]').each(function(){
          var link_roles = $(this).attr('data_allowed_roles');
          var link_pieces = link_roles.split(',');
          var match_found = false;
          for(lp in link_pieces) {
            var link_role = link_pieces[lp].trim().toLowerCase();
            if (roles[link_role]) {
              match_found = true;
              break;
            }
          } // lp

          if (match_found) {
            $(this).show();
          }
          else {
            $(this).hide();
          }
        });
      }
    },

    rebuild_domains_menu: function(data)
    {
      $('#site-navigation option').remove();
      var domains = data.domains;
      var is_admin = data.is_admin;

      if (domains.length > 1) {
        $('#site-navigation').append("<option value='-1'>All Domains</option>");
      }
      else  if (domains.length == 0) {
        $('#site-navigation').append("<option value=''>No Domains Available</option>");
        return
      }

      var show_location_names_as_menu_options = $('#show_location_names_as_menu_options').val();
      var selected_domain_id = Common.getSelectedSiteIdFromCookie();
      for (var i=0; i < domains.length; i++)
      {
        var domain = domains[i];
        var selected = (selected_domain_id == domain['id']) ? "selected" : '';
        var option_html = "<option " + selected + " data-url='" + domain['url'] + "' data-domain='" + domain['domain_name'] + "' value='" + domain['id'] + "''>" + domain['host_name'];
        if (show_location_names_as_menu_options === 'true') {
          option_html = "<option " + selected + " data-url='" + domain['url'] + "' data-domain='" + domain['domain_name'] + "' value='" + domain['id'] + "''>" + domain['location_name'];
        }

        if (is_admin) {
          option_html += "&nbsp;(" + domain['id'] + ")";

          if (domain['hosted_by'] !== null) {
            option_html += " - " + domain['hosted_by'];
          }
        }

        option_html += "</option>";
        $('#site-navigation').append(option_html);
      } // for
    },

    get_domain_settings: function()
    {
      var result = {};
      var data = Cookies.get('domain_settings');

      // Scheduled reports store the domain settings on the page. Overriding the cookies.
      if(typeof domain_enc_settings_str !== 'undefined') {
        data = domain_enc_settings_str;
      }
      if (data) {
        result = JSON.parse( decodeURIComponent(data) );
      }
      //console.log("TEST = " + JSON.stringify(result));
      return result;
    },

    is_automotive_industry: function()
    {
      var is_automotive_industry = false
      var domain_settings = Common.get_domain_settings()
      if (domain_settings.industry !== undefined && domain_settings.industry !== null)
      {
        is_automotive_industry = domain_settings.industry.is_automotive
      }

      return is_automotive_industry
    },

    // https://stackoverflow.com/questions/14195530/how-to-display-raw-json-data-on-a-html-page
    render_displayable_json: function(json)
    {
      var jsonStr = JSON.stringify(json);
      var f = {brace: 0};

      var regeStr = jsonStr.replace(/({|}[,]*|[^{}:]+:[^{}:,]*[,{]*)/g, function (m, p1) {
      var rtnFn = function() {
              return '<div style="text-indent: ' + (f['brace'] * 20) + 'px;">' + p1 + '</div>';
          },
          rtnStr = 0;
          if (p1.lastIndexOf('{') === (p1.length - 1)) {
              rtnStr = rtnFn();
              f['brace'] += 1;
          } else if (p1.indexOf('}') === 0) {
               f['brace'] -= 1;
              rtnStr = rtnFn();
          } else {
              rtnStr = rtnFn();
          }
          return rtnStr;
      });
      return regeStr;
    },

    get_auto_group_settings: function()
    {
      var result = {};
      var data = Cookies.get('auto_group_settings');

      // Scheduled reports store the group settings on the page. Overriding the cookies.
      if(typeof group_enc_settings_str !== 'undefined') {
        data = group_enc_settings_str;
      }

      if (data) {
        result = JSON.parse( decodeURIComponent(data) );
      }
      return result;
    },

    get_branding_checksum: function()
    {
      var result = null;
      var data = Cookies.get('auto_group_settings_checksum');
      if (data) {
        result = data;
      }
      return result;
    },

    // Returns true if the layout contains this DOM element and it value is true.
    in_pdf_mode: function()
    {
      var pdf_mode = $('#pdf_mode').val();
      return (pdf_mode == "true");
    },

    handle_generic_ajax_error: function()
    {
      console.log('There was an ajax error')
    },

    // copied from plugins.js
    // if we chsnge the mask, need to change it in plugins too.
    mask_inputs: function()
    {
      $("input.mask_phone").mask('(999) 999-9999');
    },

    clearLocalStorageFilters: function() {
      // filters for running detail reports are saved in localStorage
      localStorage.removeItem("filters");
      localStorage.removeItem("group_path");
    },

    formatNumberWithCommas(number) {
      return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
  }
}();

$(document).ready(function() {
  Common.init();
}); 